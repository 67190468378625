@charset "utf-8";
/* CSS Document */

/* reset */
html {
  overflow: scroll;
}
body {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: center;
  line-height: 1.3;
  font-size: 80%;
  line-height: 1.4em;
}
div {
  zoom: 1; /*for IE 5.5-7*/
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}
caption,
th {
  text-align: left;
}
hr,
legend {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
img,
abbr,
acronym,
fieldset,
a {
  border: 0;
}
ul li {
  list-style-type: none;
}
img {
  display: inline-block;
}
a {
  text-decoration: none;
  color: #a0cecf;
}
a:hover {
  text-decoration: underline;
  color: #a0cecf;
}
a img {
  -webkit-transition: opacity 0.5s;
}
a:hover img,
a:hover img {
  background: none;
  opacity: 0.7;
  filter: alpha(opacity= "70");
}

/* base */
body {
  text-align: center;
}
#wrap {
  width: 857px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
}
#head {
  text-align: left;
  overflow: hidden;
}
#head h1 a img {
  float: left;
  margin: 10px;
}
#head p img {
  float: left;
  margin: 22px 10px 0 10px;
}

#head #snipet {
  font-size: 10px;
  padding-top: 20px;
}
#head #navi_area {
  float: left;
  /*	clear: both; */
  overflow: hidden;
  /*	height: 120px; */
  padding-top: 60px;
}
#head #navi_area ul li {
  float: left;
  margin: 0 20px;
}

#main_area {
  /* background-image:url(img/top_back.jpg);*/
  height: 580px;
}

#main_text {
  width: 500px;
  padding: 155px 0 0 180px;
  text-align: center;
}

#main_text2 {
  width: 500px;
  padding: 140px 0 0 180px;
  text-align: center;
}

#main_text3 {
  width: 500px;
  padding: 140px 0 0 180px;
}

#main_text h2 {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 20px;
}

#foot #foot_in {
  text-align: center;
  width: 810px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5px;
}
#foot #foot_in ul li {
  float: left;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #fff;
  margin-right: 17px;
  padding-left: 12px;
  padding-top: 3px;
  font-size: 11px;
  line-height: 1em;
}
#foot #foot_in ul li a {
  color: #000; /*文字の色の変更*/
  text-decoration: none;
}
#foot #foot_in ul li a:hover {
  text-decoration: underline;
}
#foot #foot_in #copy {
  clear: both;
  margin-top: 15px;
  display: inline-block;
  font-size: 11px;
  margin-right: 27px;
  margin-bottom: 80px;
}

.table {
  margin: 30px 0 0 100px;
}

.table th {
  padding: 6px;
}

.table td {
  padding: 6px;
}
